<template>
  <div class="container">
    <div class="subheader">
      <template v-if="initialized">
        <div class="title" v-if="fileId">
          <router-link
            :to="{
              name: 'files',
              params: { sectionId: sectionId, fileId: file.id },
            }"
            class="link"
          >
            {{ originalFile.name }}
          </router-link>
        </div>
        <div class="title" v-if="!fileId">Nieuw bestand</div>
        <div class="buttons" v-if="enabled">
          <button
            type="button"
            class="btn btn-invisible-grey"
            @click="deleteExportField"
            v-if="fileId && false"
          >
            Verwijderen
          </button>
          <button
            type="button"
            class="btn btn-dark"
            @click="saveFile"
            :disabled="!changed"
          >
            Opslaan
          </button>
        </div>
      </template>
    </div>

    <div class="scroller">
      <div class="file" v-if="initialized">
        <div v-if="validationErrors" class="alert alert-danger">
          <div v-for="(v, k) in validationErrors" :key="k">
            <p v-for="error in v" :key="error" class="text-sm">
              {{ error }}
            </p>
          </div>
        </div>

        <div class="field">
          <label>Sectie</label>
          <select v-model="file.file_section_id" :disabled="!enabled">
            <option
              v-for="section in sections"
              :value="section.id"
              :key="section.name"
            >
              {{ section.name }}
            </option>
          </select>
        </div>

        <div class="field">
          <label>Naam</label>
          <input
            type="text"
            v-model="currentTranslation.name"
            :placeholder="fallback('name')"
          />
        </div>

        <div class="field">
          <label>Beschrijving</label>
          <textarea
            type="text"
            v-model="currentTranslation.description"
            :placeholder="fallback('description')"
          />
        </div>

        <div class="field">
          <label>Bestand</label>
          <div
            class="dd-upload"
            v-cloak
            @drop.prevent="addFile"
            @dragover.prevent
          >
            <p class="uploadinfo" v-if="!files">
              Sleep een bestand hierin of klik hieronder op "selecteer een
              bestand"
            </p>
            <ul>
              <li v-if="files">
                {{ files.name }} ({{ files.size | kb }} kb)
                <button @click="clearFile" title="Remove">X</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="field">
          <label></label>
          <label for="file" class="upload">Selecteer bestand</label>
          <input
            type="file"
            id="file"
            ref="file"
            @input="changeFile"
            accept="image/png"
          />
          <font-awesome-icon
            v-if="currentTranslation.contents"
            icon="times"
            class="remove"
            @click="clearFile"
          />
        </div>

        <div class="field">
          <label></label
          ><span class="infofield">.png formaat en maximaal 4MB</span>
        </div>

        <div class="field">
          <label>Bestandsnaam</label>
          <template v-if="preview.type === 'contents'">
            <span class="infofield" :class="{ fallback: preview.fallback }">{{
              preview.filename
            }}</span>
          </template>
          <template v-else-if="preview.type === 'url'">
            <a
              class="infofield"
              :class="{ fallback: preview.fallback }"
              :href="preview.url"
              target="_blank"
              >{{ preview.filename }}</a
            >
            <font-awesome-icon
              :icon="['fal', 'external-link']"
              class="info-icon"
            />

            <font-awesome-icon
              v-if="!preview.fallback"
              icon="times"
              class="remove"
              title="Bestand verwijderen"
              @click="removeFile"
            />
          </template>
        </div>
        <div class="field">
          <label>Preview</label>
          <template v-if="preview.type === 'url'">
            <a class="infofield" :href="preview.url" target="_blank">
              <img
                :class="{ fallback: preview.fallback }"
                :src="preview.url"
                height="250"
                alt="Image preview..."
              />
            </a>
          </template>
          <template v-if="preview.type === 'contents'">
            <img
              :class="{ fallback: preview.fallback }"
              :src="preview.url"
              height="250"
              alt="Image preview..."
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import api from '@/api';
import { cloneDeep, isEqual, mapValues } from 'lodash';
import { supportedReportLanguages, wrapValue } from '@/languages';

const supportedLanguages = supportedReportLanguages;

// Returns true if no values have been filled out for this translation
function isEmpty(translation) {
  return (
    !translation.name &&
    !translation.description &&
    !translation.original_filename
  );
}

function factory(from) {
  from = from ?? {};
  return {
    name: from.name || null,
    description: from.description || null,
    original_filename: from.original_filename || null,
    contents: from.contents || null,
    url: from.url ?? null,
  };
}

export default {
  props: ['sectionId', 'fileId', 'reportLanguage'],
  data() {
    return {
      rules: null,
      questions: null,
      file: null,
      originalFile: null,
      flagInitialized: false,
      popup: null,
      localPreview: null,
      files: null,
      sections: null,

      validationErrors: null,
    };
  },
  filters: {
    kb: function (val) {
      return Math.floor(val / 1024);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.guard(next);
  },
  beforeRouteUpdate(to, from, next) {
    this.guard(next);
  },
  created() {
    this.load();
  },
  computed: {
    loaded() {
      return this.file !== null;
    },
    initialized() {
      return this.loaded && this.flagInitialized;
    },
    changed() {
      return !isEqual(this.file, this.originalFile);
    },
    enabled() {
      return true;
    },
    exists() {
      return this.fileId > 0;
    },
    currentTranslation() {
      return this.file.translations[this.reportLanguage];
    },

    // Returns the preview to be displayed in the current form, taking
    // the priority of appearance into account. We first look at the
    // active translation, and if that doesn't have anything specified,
    // we look at the first translation that does.
    preview() {
      const descriptor = (lang, fallback) => {
        const t = this.file.translations[lang];
        if (t.contents) {
          return {
            fallback,
            type: 'contents',
            url: t.contents,
            filename: fallback
              ? wrapValue(lang, t.original_filename)
              : t.original_filename,
          };
        }

        if (t.url) {
          return {
            fallback,
            type: 'url',
            url: t.url,
            filename: fallback
              ? wrapValue(lang, t.original_filename)
              : t.original_filename,
          };
        }

        return null;
      };

      const active = descriptor(this.reportLanguage, false);
      if (active) {
        return active;
      }

      for (const lang of supportedLanguages) {
        const placeholder = descriptor(lang, true);
        if (placeholder) {
          return placeholder;
        }
      }

      return {
        url: null,
        type: 'missing',
      };
    },
  },
  watch: {
    loaded() {
      if (this.loaded) {
        this.initialize();
        this.flagInitialized = true;
      } else {
        this.flagInitialized = false;
      }
    },
  },
  methods: {
    guard(next) {
      if (this.changed) {
        this.$emit('popup', {
          title: 'Weet je zeker dat je wilt weggaan?',
          content: 'De wijzigingen in het veld zullen niet opgeslagen worden',
          image: 'question',
          confirm: () => {
            this.$emit('popup', null);
            next();
          },
          cancel: () => {
            this.$emit('popup', null);
            next(false);
          },
        });
      } else {
        next();
      }
    },
    async load() {
      if (this.fileId > 0) {
        // Load existing File object
        const response = await api.getFile(this.fileId);
        this.setFile(response.data);
      } else {
        this.setFile({
          file_section_id: this.sectionId,
          translations: [],
        });
      }

      // Load sections for dropdown
      this.sections = (await api.getFileSections(this.version)).data;
    },
    setFile(file) {
      // Make sure each supported has all placeholder object values
      for (const lang of supportedLanguages) {
        file.translations[lang] = factory(file.translations[lang]);
      }

      this.file = file;
    },
    initialize() {
      this.originalFile = cloneDeep(this.file);
    },

    // Check whether the given field is present in the current translation
    hasTranslation(key) {
      return !!this.file.translations[this.reportLanguage][key];
    },

    // Placeholder value for a field, in case it has no own value. We only
    // have to iterate fallbacks, this is not used for a primary value.
    fallback(key, nowrap) {
      for (const lang of supportedLanguages) {
        const value = this.file.translations[lang][key];
        if (value) {
          return nowrap || lang === this.reportLanguage
            ? value
            : wrapValue(lang, value);
        }
      }

      return null;
    },

    deleteExportField() {
      this.$emit('popup', {
        title: 'Weet je zeker dat je dit bestand wilt verwijderen? ',
        content: '',
        image: 'question',
        confirm: () => {
          this.$emit('popup', null);
          api.deleteFile(this.file.id).then(() => {
            this.$router.push({
              name: 'files',
              params: { sectionId: this.file.file_section_id },
            });
            this.$emit('refreshMiddle');
          });
        },
        cancel: () => this.$emit('popup', null),
      });
    },
    pushFile(file) {
      this.$router.push({
        name: 'files',
        params: { sectionId: file.file_section_id, fileId: file.id },
      });
    },
    async saveFile() {
      // Duplicate the file object, replacing any translation
      // without a filename with `null` (which will either cause it to
      // not be created or just to be deleted).
      const file = {
        ...this.file,
      };

      file.translations = mapValues(file.translations, (translation) => {
        return isEmpty(translation)
          ? // Clear entire translation
            null
          : // Run the translation through factory(), because this will
            // `null` all values that weren't given.
            factory(translation);
      });

      if (this.exists) {
        await this._saveUpdate(file);
      } else {
        await this._saveCreate(file);
      }

      this.files = null;
    },

    // Saves updates to an existing file
    async _saveUpdate(file) {
      try {
        const response = await api.updateFile(file);
        this.setFile(response.data);
        this.initialize();
      } catch (e) {
        this.validationErrors = e.response.data.errors;
        return;
      }

      this.validationErrors = null;

      if (this.file.file_section_id !== parseInt(this.sectionId)) {
        this.pushFile(this.file);
      } else {
        this.$emit('refreshMiddle');
      }
    },

    // Saves updates by creating a new file
    async _saveCreate(file) {
      try {
        const response = await api.insertFile(file);
        this.setFile(response.data);
        this.initialize();
        this.$emit('refreshMiddle');
        this.pushFile(this.file);
        this.validationErrors = null;
      } catch (e) {
        this.validationErrors = e.response.data.errors;
      }
    },

    changeFile(e, dropped) {
      let file = dropped ? dropped : this.$refs.file.files[0];
      if (!file) {
        this.clearFile();
        return;
      }

      if (file.size > 1024 * 1024 * 4) {
        alert('File too big (> 4MB)');
        return;
      }

      // TODO The backend actually only supports png at this point, that seems
      //    unnecessarily limiting.
      if (/*file.type !== 'image/jpeg' && */ file.type !== 'image/png') {
        alert('No valid image');
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        this.currentTranslation.contents = reader.result;
        this.currentTranslation.original_filename = file.name;
      };
      reader.onerror = (error) => {
        this.validationErrors = error;
      };
      reader.readAsDataURL(file);
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      this.files = droppedFiles[0];
      this.changeFile(e, droppedFiles[0]);
    },

    clearFile() {
      this.currentTranslation.original_filename =
        this.originalFile.translations[this.reportLanguage]?.original_filename;
      this.currentTranslation.contents = null;
      this.files = null;
      this.$refs.file.value = '';
    },

    // Removes the file present on the active translation
    removeFile() {
      this.currentTranslation.original_filename = null;
      this.currentTranslation.contents = null;
      this.currentTranslation.url = null;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scroller {
  overflow-y: auto;
}

.file {
  padding-top: 20px;
  padding-bottom: 60px;
  font-family: $font-family-gitlab;
  display: flex;
  flex-direction: column;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert {
  position: relative;
  padding: 5px 10px;
  margin: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.field {
  display: flex;
  flex-direction: row;
  padding: 6px 0;

  label {
    width: 120px;
    text-align: right;
    color: grey;
    padding: 6px 14px 0 0;
    font-size: 14px;
  }

  textarea:disabled,
  input:disabled,
  select:disabled {
    background-color: $bg-color-disabled;
  }

  input,
  textarea,
  select {
    border-radius: 3px;
    border: 1px solid lightgrey;
    font-size: 14px;
    padding: 4px 6px;
    min-height: 24px;
    margin: 0 10px 0 0;
    outline-style: none;
  }

  input {
    width: 300px;
  }

  textarea {
    width: calc(100% - 240px);
    height: 8em;
    line-height: 1.4;
  }

  .multiselect {
    width: 900px;
  }

  button {
    margin: 15px 5px;
  }

  label:not(.upload) {
    text-align: right;
    margin: 6px 15px 0 0;
    width: 120px;
    color: grey;
    height: 24px;
  }

  label.upload {
    padding: 7px 14px;
    font-size: 14px;
    border-radius: 3px;
    white-space: nowrap;
    user-select: none;
    border: 1px solid #6a103a;
    background-color: white;
    color: #6a103a;
    cursor: pointer;
    margin-right: 10px;
  }

  label.upload:hover {
    background-color: #f6f6f6;
  }

  #file {
    display: none;
  }

  input {
    width: 600px;
  }

  input:disabled {
    background-color: #f6f6f6;
  }

  .dd-upload {
    display: flex;
    background-color: rgba(248, 248, 248, 0.1);
    width: 610px;
    height: 100px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    color: #465569;
    margin-bottom: 10px;

    .uploadinfo {
      display: flex;
      align-items: center;
      margin: 10px 10px;
    }
  }

  .infofield {
    margin: 6px 0 6px 6px;
  }

  .info-icon {
    margin: 6px 0 6px 6px;
  }

  .remove {
    margin: 8px;
    cursor: pointer;
  }

  .input-rule {
    width: 800px;
  }

  .input-text {
    margin: 0;
    width: 786px;
    height: 80px;
  }
}

.fallback {
  opacity: 0.3;
}
</style>
