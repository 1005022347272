<template>
  <transition name="modal">
    <div class="mask" @click="$emit('save')">
      <div class="modal" @click.stop.prevent="">
        <div class="close">
          <font-awesome-icon
            class="icon close-button"
            icon="times"
            size="1x"
            @click="$emit('save')"
          />
        </div>
        <div class="content" v-if="loaded">
          <div class="field">
            <label>Vraag / Regel</label>
            <v-select
              :value="input"
              label="inputDisplay"
              :options="inputs"
              :getOptionKey="(o) => o.inputId"
              @input="setInput"
              :clearable="true"
              :searchable="true"
              class="input-question"
              placeholder="ALTIJD"
            />
          </div>
          <div class="field" v-if="line.input_option">
            <label>Keuze</label>
            <v-select
              :getOptionLabel="(option) => option.text.nl"
              :value="line.input_option"
              :options="options"
              @input="setOption"
              :clearable="false"
              :searchable="false"
              class="input-option"
            />
          </div>
          <div class="field" v-if="validOperators.length > 1 && input">
            <label>Operator</label>
            <v-select
              v-model="localLine.operator"
              label="label"
              :options="validOperators"
              :clearable="false"
              :searchable="false"
              class="input-operator"
            />
          </div>
          <div
            class="field"
            v-if="line.operator && line.operator.operandType === 'numeric'"
          >
            <label>Waarde</label>
            <input
              v-model="localLine.operand"
              type="text"
              class="input-value"
            />
          </div>
          <div
            class="field"
            v-if="line.operator && line.operator.operandType === 'score'"
          >
            <label>Score</label>
            <input
              v-model="localLine.operand"
              type="text"
              class="input-score"
            />
          </div>
        </div>
        <div class="buttons">
          <button class="action btn btn-dark" @click="$emit('save')">OK</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import _, { cloneDeep } from 'lodash';

export default {
  props: ['version', 'line', 'outputType', 'questions', 'rules', 'operators'],
  created() {
    this.init();
  },
  computed: {
    loaded() {
      return (
        this.questions !== null &&
        this.rules !== null &&
        this.operators !== null
      );
    },
    inputs() {
      if (!this.loaded) {
        return null;
      }

      let questions = this.questions.map((q) => {
        q = _.cloneDeep(q);
        q.inputId = 'q' + q.id;
        q.inputType = 'question';
        q.inputDisplay = this.truncate('#V' + q.id + ' ' + q.text.nl);
        return q;
      });

      questions = _.sortBy(questions, 'id');
      questions = questions.filter((q) => this.isValidQuestion(q));

      let rules = this.rules.map((r) => {
        r = _.cloneDeep(r);
        r.inputId = 'r' + r.id;
        r.inputType = 'rule';
        r.inputDisplay = this.truncate('#R' + r.id + ' ' + r.label);
        return r;
      });

      rules = _.sortBy(rules, 'id');

      return questions.concat(rules);
    },
    options() {
      if (this.localLine.input_option) {
        return this.questions.find(
          (q) => q.id === this.localLine.input_option.question.id
        ).options;
      } else {
        return this.localLine.input_question.options;
      }
    },
    input() {
      if (this.localLine.input_rule !== null) {
        return this.inputs.find(
          (input) =>
            input.inputType === 'rule' &&
            input.inputId === 'r' + this.localLine.input_rule.id
        );
      }
      if (this.localLine.input_question !== null) {
        return this.inputs.find(
          (input) =>
            input.inputType === 'question' &&
            input.inputId === 'q' + this.localLine.input_question.id
        );
      }
      if (this.localLine.input_option !== null) {
        return this.inputs.find(
          (input) =>
            input.inputType === 'question' &&
            input.inputId === 'q' + this.localLine.input_option.question.id
        );
      }

      return null;
    },
    inputIsQuestion() {
      return this.input?.inputType === 'question';
    },
    inputIsRule() {
      return this.input?.inputType === 'rule';
    },
    inputType() {
      if (this.inputIsQuestion) {
        return this.isBooleanQuestion(this.input) ? 'boolean' : 'numeric';
      } else if (this.inputIsRule) {
        return this.input.operator.outputType === 'boolean'
          ? 'boolean'
          : 'numeric';
      }
      return 'boolean';
    },
    validOperators() {
      return this.operators.filter((o) => this.isValidOperator(o));
    },
    // See vue/no-mutating-props rule in .eslintrc
    localLine() {
      return this.line;
    },
  },
  watch: {
    loaded() {
      this.init();
    },
  },
  methods: {
    init() {
      if (this.loaded) {
        this.enforceValidOperator();
        this.enforceValidQuestion();
      }
    },
    truncate(value, length = 100) {
      return value.length > length ? value.substring(0, length) + '...' : value;
    },
    isValidOperator(operator) {
      return (
        operator.inputType === this.inputType &&
        operator.outputType === this.outputType &&
        (!operator.resource || operator.resource === this.input?.inputType)
      );
    },
    isValidQuestion(question) {
      return (
        question.section &&
        (this.isOpenQuestion(question) || this.isBooleanQuestion(question))
      );
    },
    isOpenQuestion(question) {
      return ['text', 'number'].includes(question.type);
    },
    isBooleanQuestion(question) {
      return ['select', 'multiselect', 'score'].includes(question.type);
    },
    setInput(input) {
      if (!input) {
        this.localLine.input_question = null;
        this.localLine.input_option = null;
        this.localLine.input_rule = null;
      } else {
        if (input.inputType === 'question') {
          let question = this.questions.find((q) => q.id === input.id);

          if (this.isBooleanQuestion(input)) {
            let option = cloneDeep(question.options[0]);
            option.question = question;

            this.localLine.input_question = null;
            this.localLine.input_option = option;
            this.localLine.input_rule = null;
          } else {
            this.localLine.input_question = question;
            this.localLine.input_option = null;
            this.localLine.input_rule = null;
          }
        } else {
          this.localLine.input_question = null;
          this.localLine.input_option = null;
          this.localLine.input_rule = this.rules.find((r) => r.id === input.id);
        }
      }

      this.enforceValidOperator();
    },
    enforceValidOperator() {
      if (
        !this.localLine.operator ||
        !this.isValidOperator(this.localLine.operator)
      ) {
        this.localLine.operator = this.validOperators[0];
      }
    },
    enforceValidQuestion() {
      if (
        this.localLine.input_question &&
        !this.isValidQuestion(this.localLine.input_question)
      ) {
        this.setInput(null);
      }
      if (
        this.localLine.input_option &&
        !this.isValidQuestion(this.localLine.input_option.question)
      ) {
        this.setInput(null);
      }
    },
    setOption(option) {
      option.question = _.cloneDeep(this.localLine.input_option.question);
      delete option.question.options;
      this.localLine.input_option = option;
    },
  },
};
</script>

<style lang="scss"></style>

<style scoped lang="scss">
.mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $font-family-gitlab;
    width: 1000px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-top: solid 3px $color-main;

    .close {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .close-button {
        margin: 8px;
        color: #afafaf;
        cursor: pointer;
      }

      .close-button:hover {
        color: #8b8b8b;
      }
    }

    .content {
      width: 100%;
      min-height: 131px;
      margin: 10px 0 0 0;

      .field {
        display: flex;
        flex-direction: row;
        margin: 5px 0 10px 0;
        font-size: 14px;

        label {
          text-align: right;
          margin: 6px 15px 0 0;
          width: 100px;

          color: grey;
        }

        .input-question,
        .input-option {
          width: 800px;
        }

        .input-operator {
          width: 214px;
        }

        .input-value,
        .input-score {
          margin: 0;
          width: 200px;
        }
      }
    }

    .buttons {
      text-align: center;
      padding: 15px 0 10px 0;

      .btn {
        width: 140px !important;
        margin: 0 5px !important;
      }
    }

    input,
    textarea,
    select {
      border-radius: 3px;
      border: 1px solid lightgrey;
      font-size: 14px;
      padding: 4px 6px;
      min-height: 24px;
      margin: 0 10px 15px 10px;
      outline-style: none;
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
